import { render } from "react-dom";
import { BrowserRouter, Routes, Route, useRoutes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import NiceModal from "@ebay/nice-modal-react";

import Home from "screens/Home";
import Mint from "screens/Mint";

import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import { AnimatePresence } from "framer-motion";
import { cloneElement } from "react";
import Header from "components/layout/Header";

const rootElement = document.getElementById("root");

function App() {
  const element = useRoutes([
    {
      path: "/",
      element: <Home key="home"></Home>,
    },
    {
      path: "/mint",
      element: <Mint key="page"></Mint>,
    },
  ]);

  if (!element) return null;

  // Add key to make AnimatePresence work
  const clone = cloneElement(element, { key: element.props.children.key });

  return (
    <div className="w-screen min-h-screen flex flex-row justify-center">
      <NiceModal.Provider>
        <div className=" w-full h-full flex flex-col px-4 pt-10 max-w-7xl">
          <Header />
          <AnimatePresence
            exitBeforeEnter
            onExitComplete={() => console.log("exit complete")}
          >
            {clone}

            {/* {clone} */}
          </AnimatePresence>
        </div>
        <ToastContainer />
      </NiceModal.Provider>
    </div>
  );
}

render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  rootElement
);
