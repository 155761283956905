import { ethers } from "ethers";

async function connectWallet() {
  try {
    // @ts-ignore
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    await provider.send("eth_requestAccounts", []);
  } catch (e) {
    // console.log("e connectWallet:", e);
  }
}

export default connectWallet;
