import PetOne from "../../assets/images/secret.png";

function MintHero() {
  return (
    <div className="flex flex-col justify-center items-center mt-8 md:mt-16 md:flex-row md:items-start">
      <div className="w-52 h-52 overflow-hidden">
        <img className="h-full w-full" src={PetOne} />
      </div>
      <div className="px-2 mt-4 md:px-0 md:mt-0 md:ml-8">
        <h2 className="font-bold text-3xl">
          Say hello to your new best friend
          <br />
          <span className="font-medium text-xl">
            (if you can keep them alive.)
          </span>
        </h2>
        <p className="max-w-md mt-4 text-offBlack-600">
          Your pet will guide you to the riches you seek.. but only if they
          survive! In this scary world, food is incredibly scarce. Your pet
          might wake you up late night to feed them. But it’s the price we pay
          to see these cute faces. So pick a name and
        </p>
      </div>
    </div>
  );
}

export default MintHero;
