import Letter from "../../assets/images/letter.png";

function MetaHome() {
  return (
    <div className="w-full flex flex-col items-center md:mt-24">
      <div className="w-40 border-t border-gray-400 mb-4 " />
      <h2 className="font-semibold text-4xl">The Meta</h2>
      <p className="text-center font-medium text-xl leading-relaxed max-w-2xl mt-4">
        In this cruel world, the apes have forced other animals to compete in
        this ruthless competition. Adopt a pet and try to save their lives,
        don't let it starve to death. If your pet survives this 2 week
        competition, you and your pet can go home safely with the jackpot.
      </p>
      <img
        className="w-80 md:w-96 rotate-6 mt-16 hover:scale-[1.01] transition-transform"
        src={Letter}
      />
    </div>
  );
}

export default MetaHome;
