import { useEffect, useState } from "react";
import cleanAddress from "utils/js/cleanAddress";
import getConnectedAddress from "utils/web3/getConnectedAddress";

function useWallet(): [boolean, string, string] {
  const [loading, setLoading] = useState(true);
  const [address, setAddress] = useState("");

  useEffect(() => {
    getConnectedAddress().then((address) => {
      setAddress(address);
      setLoading(false);
    });
  }, []);

  let buttonText = "Loading";

  if (!loading) {
    buttonText = "Connect Wallet";
    if (address) {
      buttonText = cleanAddress(address);
    }
  }

  return [loading, address, buttonText];
}

export default useWallet;
