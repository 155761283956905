import { animate, AnimatePresence, LayoutGroup, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import Plus from "../../assets/input/plus.svg";
import Minus from "../../assets/input/minus.svg";
import { toast } from "react-toastify";

function MintInput() {
  const [mintCount, setMintCount] = useState(1);

  const onAdoptPress = () => {
    toast("👀 Try that on the 18th!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <div className="mt-10 flex flex-col items-center">
      <AnimatePresence>
        <div className="space-x-4 flex flex-row justify-center items-center  w-72 px-2 py-4 text-lg shadow-xl bg-white rounded-xl ">
          <img
            onClick={() => {
              if (mintCount > 1) {
                setMintCount(mintCount - 1);
              }
            }}
            className="p-2 hover:cursor-pointer"
            src={Minus}
          />
          <p className="text-center w-10 text-2xl font-semibold ">
            {mintCount}
          </p>

          <img
            onClick={() => {
              if (mintCount < 5) {
                setMintCount(mintCount + 1);
              }
            }}
            className="p-2 hover:cursor-pointer"
            src={Plus}
          />
        </div>
        <div className="w-72 flex flex-row justify-between mt-2 text-sm"></div>
      </AnimatePresence>

      <div
        onClick={onAdoptPress}
        className="cursor-pointer hover:scale-105 transition-transform rounded-3xl shadow-md mt-6 bg-[#FFA339] shadow-gray-400 px-16 py-2"
      >
        <h2 className="text-white font-bold text-lg">Adopt</h2>
      </div>
    </div>
  );
}

export default MintInput;
