import ConnectWallet from "components/general/ConnectWallet";
import { Link } from "react-router-dom";
import TwitterIcon from "../../assets/images/twitter.png";

function DisabledLink({ text }: { text: string }) {
  return (
    <p className="w-full text-center text-gray-500  transition-colors font-bold  md:w-auto">
      {text}
    </p>
  );
}
function HeaderLink({ to, text }: { to: string; text: string }) {
  return (
    <Link
      className="w-full text-center text-offBlack-500 transition-colors font-bold hover:text-gray-500 md:w-auto"
      to={to}
    >
      {text}
    </Link>
  );
}

function WebButtons() {
  return (
    <div className="hidden flex-row justify-center items-center space-x-6 md:flex">
      {/* <HeaderLink to="/" text="My Pets" /> */}
      <DisabledLink text="My Pets" />
      <HeaderLink to="/mint" text="Mint" />
      <ConnectWallet />
      <a target="_blank" href="https://twitter.com/SpartanTrainee">
        <img
          className="h-4 hover:scale-125 transition-transform"
          src={TwitterIcon}
        />
      </a>
    </div>
  );
}

function MobileButtons() {
  return (
    <div className="flex flex-col md:hidden items-center w-full">
      <div className="flex flex-row justify-between items-center w-full mt-4">
        {/* <HeaderLink to="/" text="My Pets" /> */}
        <DisabledLink text="My Pets" />

        <HeaderLink to="/mint" text="Mint" />
      </div>
      <ConnectWallet />
    </div>
  );
}

function Header() {
  return (
    <div>
      <div className="flex flex-col justify-between items-center md:flex-row">
        <Link
          to={"/"}
          className="font-bold w-full text-3xl text-center md:text-left md:w-auto"
        >
          The Degen
          <br />
          Hungry Games
        </Link>
        <WebButtons />
        <MobileButtons />
      </div>
    </div>
  );
}

export default Header;
