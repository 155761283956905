function QuestionAnswer({
  question,
  answer,
}: {
  question: string;
  answer: string;
}) {
  return (
    <div className="w-full mt-10 px-4 max-w-2xl md:px-0">
      <h3 className="font-semibold text-2xl ">{question}</h3>
      <p className="leading-loose font-medium text-lg mt-2 ">{answer}</p>
    </div>
  );
}

function FAQHome() {
  return (
    <div className="w-full flex flex-col items-center mt-16">
      <div className="w-40 border-t border-gray-400 mb-4 " />
      <h2 className="font-semibold text-4xl text-center mb-4">
        Frequently Asked Questions
      </h2>
      <QuestionAnswer question="Why?" answer="Because it seems like fun!" />
      <QuestionAnswer
        question="Is this just another PFP project?"
        answer="PFP projects don't reward you for taking care of your precious mints, and usually don't die when you neglect them."
      />
      <QuestionAnswer
        question="How exactly do I keep my pet alive?"
        answer="Every day you'll have to feed your pet. The exact time when you're supposed to feed them varies by day, as does the duration of the feeding window. If you don't feed your pet, they will succumb to a slow and painful death ):"
      />
      <QuestionAnswer
        question="What happens if my pet survives the 14 days?"
        answer="You'll be able to call the claim() function on our Smart Contract / website and earn your winnings from the pot. You'll also get to keep your pet (alive) in your wallet!"
      />
      <QuestionAnswer
        question="How do I feed my pet?"
        answer={`To feed, you'll go onto the website and click the "My Pets" tab, from there you'll see your pets and be able to feed them. Feeding will cost .03 ETH (which will go into the pot.)`}
      />
      <QuestionAnswer
        question="How big is the pot?"
        answer={`Like Degen says, 70% of all proceeds (mint + feeding) goes straight into the pot. Assuming we mint out, the pot will start at 350 ETH!`}
      />
    </div>
  );
}

export default FAQHome;
