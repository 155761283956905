import { ethers } from "ethers";

async function getSignerAddress() {
  // @ts-ignore
  const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
  const signer = provider.getSigner();
  const address = await signer.getAddress();
  return address;
}

export default getSignerAddress;
