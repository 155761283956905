import getSignerAddress from "./getSignerAddress";

async function getConnectedAddress() {
  try {
    // Prompt user for account connections
    // await provider.send("eth_requestAccounts", []);
    const address = await getSignerAddress();
    return address;
  } catch (e) {
    // console.log("e", e);
    return "";
  }
}

export default getConnectedAddress;
