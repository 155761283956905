import { motion } from "framer-motion";

type Props = {
  children: React.ReactNode | React.ReactNode[];
};

function DefaultLayout({ children }: Props) {
  return (
    <motion.div
      initial={{ opacity: 0.9 }}
      animate={{ scale: 1.01, opacity: 1 }}
      transition={{ type: "spring", stiffness: 100 }}
    >
      {children}
    </motion.div>
  );
}

export default DefaultLayout;
