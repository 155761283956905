function HeroHome() {
  return (
    <div className=" w-full flex flex-col items-center justify-center mt-10  md:flex-row md:mt-24">
      <div className="w-96 flex flex-col justify-center px-6 md:mr-16 md:px-0">
        <h2 className="font-semibold text-center text-2xl md:text-left">
          You're probably wondering... what is this and how do I make money? 🤔
        </h2>

        <h2 className="font-semibold text-2xl my-6 text-center md:text-left">
          ...
        </h2>
        <h2 className="font-semibold text-2xl text-center mb-10 md:text-left md:mb-0">
          We'll let our friend @DegenSpartan explain.
        </h2>
      </div>
      <iframe
        id="twitter-widget-0"
        scrolling="no"
        className=" w-80  md:w-100 drop-shadow-2xl"
        style={{
          height: 640,

          //width: 400
        }}
        title="Twitter Tweet"
        src="https://platform.twitter.com/embed/Tweet.html?dnt=false&amp;embedId=twitter-widget-0&amp;features=eyJ0ZndfZXhwZXJpbWVudHNfY29va2llX2V4cGlyYXRpb24iOnsiYnVja2V0IjoxMjA5NjAwLCJ2ZXJzaW9uIjpudWxsfSwidGZ3X2hvcml6b25fdHdlZXRfZW1iZWRfOTU1NSI6eyJidWNrZXQiOiJodGUiLCJ2ZXJzaW9uIjpudWxsfSwidGZ3X3NwYWNlX2NhcmQiOnsiYnVja2V0Ijoib2ZmIiwidmVyc2lvbiI6bnVsbH19&amp;frame=false&amp;hideCard=true&amp;hideThread=false&amp;id=1490920699732819968&amp;lang=en&amp;origin=https%3A%2F%2Fmirror.xyz%2Fwrite&amp;sessionId=1290a8146400b87a0e180e64d3bf8db676ac8143&amp;theme=light&amp;widgetsVersion=0a8eea3%3A1643743420422&amp;hide_media=true"
        data-tweet-id="1490920699732819968"
        data-cards="hidden"
      ></iframe>
    </div>
  );
}

export default HeroHome;
