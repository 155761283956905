import NiceModal from "@ebay/nice-modal-react";
import useWallet from "hooks/general/useWallet";
import ConnectModal from "./ConnectModal";

function ConnectWallet() {
  const [loading, address, buttonText] = useWallet();
  const onClick = () => {
    if (!address && !loading) {
      NiceModal.show(ConnectModal);
    }
  };

  return (
    <div
      onClick={onClick}
      className="flex justify-center items-center bg-black px-8 py-3 rounded-3xl hover:scale-105 transition-transform cursor-pointer mt-4 md:mt-0"
    >
      <h2 className="font-bold text-white">{buttonText}</h2>
    </div>
  );
}

export default ConnectWallet;
