import DefaultLayout from "components/layout/DefaultLayout";
import MintDetails from "components/mint/MintDetails";
import MintHero from "components/mint/MintHero";
import MintInput from "components/mint/MintInput";

function Mint() {
  return (
    <DefaultLayout>
      <MintHero />
      <MintInput />
      <MintDetails />
      <div className="h-44" />
    </DefaultLayout>
  );
}

export default Mint;
