import FAQHome from "components/home/FAQHome";
import HeroHome from "components/home/HeroHome";
import MetaHome from "components/home/MetaHome";
import DefaultLayout from "components/layout/DefaultLayout";

function TwitterButton() {
  return (
    <a
      target="_blank"
      href="https://twitter.com/SpartanTrainee"
      className="flex justify-center items-center bg-twitterBlue  px-8 py-3 rounded-3xl hover:scale-105 transition-transform cursor-pointer mt-2 md:mt-0"
    >
      <h2 className="font-bold text-white">Follow us on Twitter</h2>
    </a>
  );
}

function Home() {
  return (
    <DefaultLayout>
      <HeroHome />
      <MetaHome />
      <div className="flex flex-riw justify-center items-center mt-12">
        <TwitterButton />
      </div>
      <FAQHome />
      <div className="h-96" />
    </DefaultLayout>
  );
}

export default Home;
