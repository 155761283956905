function LeftRightText({ left, right }: { left: string; right: string }) {
  return (
    <p className="font-medium text-lg mt-2 ">
      {left}
      <span className="font-bold">{right}</span>
    </p>
  );
}

function MintDetails() {
  return (
    <div className="w-full flex justify-center mt-4">
      <div className="mt-2 flex flex-col  md:flex-row justify-between w-full max-w-2xl">
        <div>
          <h2 className="text-3xl font-bold ">The Numbers</h2>
          <LeftRightText left="Price: " right=".15 ETH" />
          <LeftRightText left="Current Pool Size: " right="N/A" />
          {/* <LeftRightText left="Animals Left: " right="5,000" /> */}
        </div>
        <div className="mt-10 md:mt-0">
          <h2 className="text-3xl font-bold">More Details</h2>
          <LeftRightText left="Mint Date: " right="02/18/22" />
          <LeftRightText left="Feeding Cost: " right=".03 ETH" />
          {/* <LeftRightText left="" right="" /> */}
        </div>
      </div>
    </div>
  );
}

export default MintDetails;
