import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { useEffect } from "react";
import { motion } from "framer-motion";

import connectWallet from "utils/web3/connectWallet";

import MetaMask from "../../assets/logos/meta.png";
import Coinbase from "../../assets/logos/coinbase.svg";
import XSVG from "../../assets/logos/x.svg";

const variants = {
  show: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  },
};

function WalletCard({
  name,
  image,
  extraStyle,
}: {
  name: string;
  image: string;
  extraStyle?: string;
}) {
  const modal = useModal();
  return (
    <div
      onClick={() => {
        connectWallet().then(() => {
          modal.remove();
          window.location.reload();
        });
      }}
      className={`  bg-slate-200 rounded-2xl px-4 h-16 flex flex-row items-center justify-between border-2 transition-colors cursor-pointer ${extraStyle}`}
    >
      <p>{name}</p>
      <img className="h-8 w-8" src={image} />
    </div>
  );
}

function ModalHeader({ modal }: { modal: any }) {
  return (
    <div className="flex flex-row justify-between items-center">
      <p className="text-xl">Connect your wallet</p>
      <img
        onClick={() => modal.remove()}
        className="h-4 w-4 cursor-pointer"
        src={XSVG}
      />
    </div>
  );
}

export default NiceModal.create(() => {
  const modal = useModal();

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  return (
    <motion.div
      transition={{ duration: 0.375 }}
      initial="hidden"
      animate="show"
      exit="hidden"
      variants={variants}
      onClick={() => modal.remove()}
      className="font-medium  top-0 fixed h-full w-screen  bg-offBlack-modal  flex justify-center items-center"
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="bg-white flex flex-col p-4 w-80 space-y-4 rounded-2xl"
      >
        <ModalHeader modal={modal} />
        <WalletCard
          name="MetaMask"
          image={MetaMask}
          extraStyle="hover:border-orange-400"
        />
        <WalletCard
          name="Coinbase Wallet"
          image={Coinbase}
          extraStyle="hover:border-blue-500"
        />
      </div>
    </motion.div>
  );
});
